<template>
  <v-col cols='12' lg='6' md='6' sm='6' xl='6' xs='12'>
    <v-card color='white' elevation='0' height='100%' width='100%'>
      <v-container class='pl-15 pr-15' fill-height>
        <v-row
          align='center'
          align-content='center'
          class='text-center'
          justify='center'
        >
          <v-col cols='12' style='max-width: 280px'>
            <div class='text-h4 font-weight-black grey--text text--darken-1'>
              {{ loginPageContent.title }}
            </div>
          </v-col>

          <v-col cols='8'>
            <div class='subtitle-1 grey--text text--darken-2'>
              {{ loginPageContent.text }}
            </div>
          </v-col>
          <v-col class='mt-3' cols='8'></v-col>
          <v-col cols='8' dense>
            <v-form ref='form' v-model='valid' lazy-validation>
              <div
                class='
                  text-caption
                  grey--text
                  text--darken-2 text-start
                  mb-2
                  font-weight-bold
                '
              >
                {{ loginPageContent.forgot_email_input_title }}
              </div>
              <v-text-field
                v-model='email'
                :rules='emailRules'
                class='rounded-lg'
                color='primary'
                hide-details='auto'
                outlined
                required
                type='email'
              ></v-text-field>
            </v-form>
          </v-col>

          <v-col class='mt-8' cols='8'>
            <v-btn
              :disabled='loading'
              :loading='loading'
              block
              class='white--text body-1 text-capitalize'
              color='primary'
              elevation='0'
              x-large
              @click='reset'
            >
              {{ loginPageContent.reset_password_button_title }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-col>
</template>
<script>
import home from '../../../services/home'
import {mapGetters} from 'vuex'

export default {
  data: () => ({
    loading: false,
    valid: true,
    email: '',
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ]
  }),
  methods: {
    async reset() {
      this.loading = true
      if (this.$refs.form.validate()) {
        try {
          const res = await home.resetPassword({
            email: this.email
          })
          if (res.status == 200) {
            this.$store.dispatch('snackbar', {
              show: true,
              color: 'green',
              message: res.data.message
            })
            setTimeout(() => {
              this.$router.replace({name: 'Login'})
              this.loading = false
            }, 4000)
          }
          this.loading = false
        } catch (err) {
          // empty
          this.loading = false
        }
      } else {
        this.loading = false
      }
    }
  },
  computed: {
    ...mapGetters('cmsStore', ['loginPageContent'])
  }
}
</script>
